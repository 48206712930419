import React, { useState } from "react";
import { Grid, Button } from "@mui/material";
import { Message } from "@mui/icons-material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

import { ComponentHeader, Paper, TextField } from "../../../components";
import TextFieldComponent from "../../custom fields/preview fields/textfield dialog components/TextFieldComponent";
import DatePickerComponent from "../../custom fields/preview fields/date dialog components/DatePicker";
import DropDownComponent from "../../custom fields/preview fields/dropdown dialog components/DropdownComponent";
import SwitchComponent from "../../custom fields/preview fields/switch dialog components/SwitchComponent";
// this below Send message feature has been commented as asked by Ashwin Sir
// import SendMessageButton from "../message send components/SendMessageButton";
import ViewConversation from "./ViewConversation";

export default function FormDisplay({
  formikProps,
  fields,
  editState,
  templates,
  pagePermissions,
  integrationDetails,
  activeBot,
  userInfo,
  leadProperties,
  conversation,
}) {
  const [validPhoneNumber, setValidPhoneNumber] = useState(false);
  const [viewConversation, setViewConversation] = useState(false);

  const handleChangePhoneNumber = (value, country, e) => {
    if (value.length <= 3) {
      setValidPhoneNumber(true);
    } else {
      setValidPhoneNumber(false);
    }
    if (
      e.target.className === "country" ||
      e.target.className === "country-name"
    ) {
      formikProps.setValues((prevVal) => ({
        ...prevVal,
        phone: "+" + country.dialCode + "",
      }));
    } else {
      formikProps.setValues((prevVal) => ({
        ...prevVal,
        phone: value,
      }));
    }
  };

  return (
    <Paper>
      <Grid item xs={12}>
        <ComponentHeader
          title="Lead details"
          subtitle={"Details of the leads."}
          buttons={[
            // ((formikProps.values.ID && !!templates) || !integrationDetails) && (
            //   <SendMessage />
            // ),
            formikProps.values.ID && conversation && (
              <Button
                onClick={() => setViewConversation(true)}
                variant="outlined"
                startIcon={<Message />}
              >
                View Conversation
              </Button>
            ),
          ]}
        />
      </Grid>

      <Grid item xs={4}>
        <TextField
          name="name"
          label="Name"
          disabled={!editState}
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={4}>
        <TextField
          name="email"
          label="Email"
          disabled={!editState}
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={4}>
        <PhoneInput
          value={formikProps.values.phone}
          onChange={(value, country, e) =>
            handleChangePhoneNumber(value, country, e)
          }
          disabled={!editState}
          inputProps={{
            name: "mobileNumber",
          }}
          onBlur={formikProps.handleBlur}
          inputStyle={{
            width: "100%",
            fontSize: "12px",
            color: !editState ? "rgba(0,0,0,0.38)" : "currentColor",
            borderColor: editState
              ? !validPhoneNumber
                ? "#4076d2"
                : "#f44336"
              : "rgba(0,0,0,0.38)",
            padding: "17.5px 14px 18.5px 58px",
            cursor: "default",
          }}
        />
        {validPhoneNumber && (
          <span className="validationError">Number is Required</span>
        )}
      </Grid>

      {fields.map((fieldDetails, index) => (
        <React.Fragment key={index}>
          {fieldDetails.control_id === "61dd2dfa1fb38be82e077ed7" && (
            <Grid item xs={4}>
              <TextFieldComponent
                formikProps={formikProps}
                fieldDetails={fieldDetails}
                disabled={!(fieldDetails.editable && editState)}
              />
            </Grid>
          )}

          {fieldDetails.control_id === "61dd2dfa1fb38be82e077ed9" && (
            <Grid item xs={4}>
              <DatePickerComponent
                formikProps={formikProps}
                fieldDetails={fieldDetails}
                disabled={!(fieldDetails.editable && editState)}
              />
            </Grid>
          )}

          {fieldDetails.control_id === "61dd2dfa1fb38be82e077ed8" && (
            <Grid item xs={4}>
              <DropDownComponent
                formikProps={formikProps}
                fieldDetails={fieldDetails}
                disabled={!(fieldDetails.editable && editState)}
              />
            </Grid>
          )}

          {fieldDetails.control_id === "61e13a0bf2c60729b06385f3" && (
            <Grid item xs={4}>
              <SwitchComponent
                formikProps={formikProps}
                fieldDetails={fieldDetails}
                disabled={!(fieldDetails.editable && editState)}
              />
            </Grid>
          )}
        </React.Fragment>
      ))}

      {formikProps.values.ID && (
        <React.Fragment>
          <Grid item xs={4}>
            <TextField
              name="createdAt"
              label="Created At"
              formikProps={formikProps}
              value={leadProperties?.createdAt}
              disabled
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              name="updatedAt"
              label="Updated At"
              formikProps={formikProps}
              value={leadProperties?.updatedAt}
              disabled
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              name="createdBy"
              label="Created By"
              value={leadProperties?.createdBy}
              disabled
              formikProps={formikProps}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              name="updatedBy"
              label="Updated By"
              disabled
              value={leadProperties?.updatedBy}
              formikProps={formikProps}
            />
          </Grid>
        </React.Fragment>
      )}
      <ViewConversation
        viewConversation={viewConversation}
        setViewConversation={setViewConversation}
        formikProps={formikProps}
        conversation={conversation}
      />
    </Paper>
  );

  // function SendMessage() {
  //   return (
  //     (!!templates || !!integrationDetails) && (
  //       <SendMessageButton
  //         formikProps={formikProps}
  //         templates={templates}
  //         pagePermissions={pagePermissions}
  //         integrationDetails={integrationDetails}
  //         activeBot={activeBot}
  //         userInfo={userInfo}
  //       />
  //     )
  //   );
  // }
}
