import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { Info } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Button, Grid, MenuItem, Typography } from "@mui/material";

import {
  ComponentHeader,
  FileUpload,
  Paper,
  TextField,
} from "../../../../../components";
import { useAxios } from "../../../../../hooks";
import SampleFileDialog from "./SampleFileDialog";
import convertDateToBackendFormat from "../convertDateToBackendFormat";
import PublicURLDialog from "../../email broadcast components/public url components/PublicURLDialog";
import EmailPreview from "../../email template components/template details component/EmailTemplatePreview";

const useStyles = makeStyles(() => ({
  divStyle: {
    display: "flex",
    backgroundColor: "rgb(203 203 203 / 34%)",
    padding: "10px",
  },
  downloadFile: {
    color: "#0075F6",
    cursor: "pointer",
    letterSpacing: "1px",
  },
  generateFile: {
    color: "#0075F6",
    cursor: "pointer",
    letterSpacing: "1px",
    margin: "0 10px",
  },
}));

export default function EmailForm({
  formikProps,
  editState,
  scheduledTime,
  setScheduledTime,
  templates,
}) {
  const classes = useStyles();
  const axios = useAxios();

  const [popUp, setPopUp] = useState(false);

  const { activeBot } = useSelector((state) => state.memberDetails);

  let newDate = new Date();
  let date_raw = newDate.getDate();
  let month_raw = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  var date = year + "-" + month_raw + "-" + date_raw;

  const [sampleFileDialog, setSampleFileDialog] = useState(false);

  const handleChange = (e) => {
    formikProps.setValues((prev) => ({
      ...prev,
      broadcastDate: e.target.value,
    }));
    var time = convertDateToBackendFormat(e.target.value);

    axios({
      url: "/broadcast/time?schedule_date=" + time,
      method: "GET",
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        setScheduledTime(response.data);
      }
    });
  };

  function handleGenetateSampleFile(count) {
    axios({
      url:
        "/broadcast_email/sample/create?agent_id=" +
        activeBot.ID +
        "&body_variable_count=" +
        (count[0]?.body_variable_count !== -1
          ? count[0]?.body_variable_count
          : -1) +
        "&subject_variable_count=" +
        (count[0]?.subject_variable_count !== -1
          ? count[0]?.subject_variable_count
          : -1) +
        "&attachments_count=" +
        (count[0]?.attachments_count !== -1 ? count[0]?.attachments_count : -1),
      method: "GET",
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        window.location.assign(response.data);
      }
    });
  }

  return (
    <Grid container spacing={2} alignItems="stretch">
      <Grid item xs={8} style={{ display: "grid" }}>
        <Paper>
          <Grid item xs={12}>
            <ComponentHeader
              title="Broadcast Email"
              subtitle={"Provide the details for creating the broadcast."}
              buttons={[<GetPublicURL />]}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              required
              label="Broadcast Name"
              name="broadcastName"
              disabled={!editState}
              formikProps={formikProps}
            />
          </Grid>

          <Grid item xs={6}>
            <FileUpload
              label="Upload File"
              name="uploadCSVFile"
              disabled={!editState}
              formikProps={formikProps}
              allowedExtension={".xlsx"}
              maxSize={16}
              folderPath={"emailBroadcast/" + activeBot.ID + "/"}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              select
              required
              label="When To Broadcast"
              name="whenToBroadcast"
              disabled={!editState}
              formikProps={formikProps}
            >
              <MenuItem value={"now"}>Now</MenuItem>
              <MenuItem value={"later"}>Later</MenuItem>
            </TextField>
          </Grid>

          {Boolean(formikProps.values.whenToBroadcast) &&
            formikProps.values.whenToBroadcast !== "now" && (
              <React.Fragment>
                <Grid item xs={6}>
                  <TextField
                    type="date"
                    name="broadcastDate"
                    label="Select Date"
                    onChange={handleChange}
                    formikProps={formikProps}
                    inputProps={{
                      min: new Date().toISOString().split("T")[0],
                    }}
                    disabled={
                      !editState || formikProps.values.whenToBroadcast === "now"
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    select
                    label="Select Time"
                    name="broadcastTime"
                    disabled={
                      !editState || formikProps.values.whenToBroadcast === "now"
                    }
                    formikProps={formikProps}
                  >
                    {scheduledTime.map((item, index) => {
                      return (
                        <MenuItem
                          key={item._id}
                          value={
                            index + 1 == scheduledTime.length
                              ? "23:59"
                              : scheduledTime[index + 1].time
                          }
                        >
                          {item.time} -{" "}
                          {`${
                            index + 1 == scheduledTime.length
                              ? "23:59"
                              : scheduledTime[index + 1].time
                          }`}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>
              </React.Fragment>
            )}

          <Grid item xs={6}>
            <TextField
              select
              label="Template Name"
              name="templateID"
              required
              disabled={!editState}
              formikProps={formikProps}
            >
              {templates?.map((items, index) => {
                return (
                  <MenuItem value={items._id}>{items.template_name}</MenuItem>
                );
              })}
            </TextField>
          </Grid>

          {/* <Grid item xs={6}>
            <TextField
              label="Sender Name"
              value={"VERISMART"}
              disabled={true}
            ></TextField>
          </Grid> */}

          {/* <Grid item xs={6}>
            <TextField
              label="Sender Email"
              value={"developer@verismart.ai"}
              disabled={true}
            ></TextField>
          </Grid> */}

          <Grid item xs={12} mt={1}>
            <div className={classes.divStyle}>
              <Info
                fontSize="small"
                color="primary"
                style={{ marginRight: "10px" }}
              />
              <Typography variant="body1">
                You need to select a template to download blank file or to
                create a file.
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} mt={1}>
            <div className={classes.divStyle}>
              <Info
                fontSize="small"
                color="primary"
                style={{ marginRight: "10px" }}
              />

              <Typography variant="body1">
                <Typography
                  variant="button"
                  id="template_download_link"
                  className={classes.downloadFile}
                  onClick={() =>
                    handleGenetateSampleFile(
                      templates?.filter(
                        (i) => i._id === formikProps.values.templateID
                      )
                    )
                  }
                  style={{ margin: "0 10px 0 0" }}
                >
                  Generate Blank File
                </Typography>
                to upload data in a proper format. If the template contains any
                multimedia variable then please provide public URL for it.
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} mt={1}>
            <div className={classes.divStyle}>
              <Info
                fontSize="small"
                color="primary"
                style={{ marginRight: "10px" }}
              />

              <Typography variant="body1">
                The attachment size limit is 25 MB per file. You can add
                multiple files as long as their combined size does not exceed 25
                MB.
              </Typography>
            </div>
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={4}>
        {Boolean(
          templates?.filter((i) => i._id === formikProps.values.templateID)
        ) && (
          <EmailPreview
            templates={templates?.filter(
              (i) => i._id === formikProps.values.templateID
            )}
          />
        )}
      </Grid>

      <SampleFileDialog
        sampleFileDialog={sampleFileDialog}
        setSampleFileDialog={setSampleFileDialog}
        templates={templates}
      />
      <PublicURLDialog popUp={popUp} setPopUp={setPopUp} />
    </Grid>
  );

  function GetPublicURL() {
    return (
      <Button variant="outlined" color="primary" onClick={() => setPopUp(true)}>
        Get Public URL
      </Button>
    );
  }
}
