import { useDispatch, useSelector } from "react-redux";
import { Visibility } from "@mui/icons-material";
import React, { Fragment, useState } from "react";
import { Button, CircularProgress, IconButton, Tooltip } from "@mui/material";
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";

import BroadcastReport from "./BroadcastReport";
import { convertDate } from "../../../../../utils";
import { useAlert, useAxios } from "../../../../../hooks";
import DataTable from "./../../../../../components/DataTable";

export default function Table({ broadcasts, setBroadcastID }) {
  const axios = useAxios();
  const { alert } = useAlert();
  const dispatch = useDispatch();
  const { activeBot } = useSelector((state) => state.memberDetails);

  const [popup, setPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadReport, setLoadReport] = useState(1);
  const [broadcastReport, setBroadcastReport] = useState(false);

  function handleBroadcastID(data) {
    setBroadcastID(data);
  }

  function handleCancelBroadcast(id) {
    setLoading(true);
    axios({
      url:
        "/broadcast_email/cancel_broadcast?agent_id=" +
        activeBot.ID +
        "&broadcast_id=" +
        id,
      method: "POST",
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        dispatch(
          alert({ message: response.message.displayMessage, type: "success" })
        );
      }
      setLoading(false);
    });
  }

  function handleBroadcastAnalyticsData(id) {
    setLoading(true);
    axios({
      url:
        "/broadcast_email/analytics?agent_id=" +
        activeBot.ID +
        "&broadcast_id=" +
        id,
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        setBroadcastReport(response.data);
        setPopup(true);

        setLoading(false);
      } else {
        setLoading(true);
      }
    });
  }

  const options = {
    sortOrder: {
      name: "created_at",
      direction: "desc",
    },
  };

  const columns = [
    {
      name: "template_id",
      label: "Template Name",
      options: {
        display: false,
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "broadcast_name",
      label: "Broadcast Name",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },

    {
      name: "sender_email_id",
      label: "Sender Email ID",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },

    // {
    //   name: "sender_name",
    //   label: "Sender Name",
    //   options: {
    //     customBodyRender: (value) => {
    //       return value ? value : "Not Available";
    //     },
    //   },
    // },

    {
      name: "scheduled_time",
      label: "Send Date",
      options: {
        customBodyRender: (value) => {
          return value ? convertDate(value) : "Not Available";
        },
      },
    },

    {
      name: "created_at",
      label: "Created At",
      options: {
        customBodyRender: (value) => {
          return value ? convertDate(value) : "Not Available";
        },
      },
    },

    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },

    {
      name: "_id",
      label: "Action",
      options: {
        customBodyRender: (value) => {
          let data = broadcasts.filter((item) => item._id === value);

          let scheduledTime = data[0].scheduled_time * 1000;
          const currentTime = new Date().getTime();
          const fifteenMinutes = 15 * 60 * 1000;

          return value ? (
            <Fragment>
              {Boolean(
                scheduledTime > currentTime + fifteenMinutes &&
                  data[0].status !== "cancelled"
              ) ? (
                <Tooltip title="Cancel Schedule Broadcast">
                  <IconButton size="large">
                    {!loading ? (
                      <CancelScheduleSendIcon
                        sx={{ color: "red" }}
                        onClick={() => handleCancelBroadcast(data[0]._id)}
                      />
                    ) : (
                      <CircularProgress />
                    )}
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="View Report">
                  {scheduledTime > currentTime &&
                  data[0].status !== "cancelled" ? (
                    "Report Generating"
                  ) : (
                    <IconButton color="primary" size="large">
                      {!loading ? (
                        <Visibility
                          onClick={() =>
                            handleBroadcastAnalyticsData(data[0]._id)
                          }
                        />
                      ) : (
                        <CircularProgress />
                      )}
                    </IconButton>
                  )}
                </Tooltip>
              )}
            </Fragment>
          ) : (
            "Not Available"
          );
        },
      },
    },

    {
      name: "template_id",
      label: "View Template",
      options: {
        customBodyRender: (value) => {
          return value ? (
            <Button
              variant="contained"
              onClick={() => handleBroadcastID(value)}
            >
              View Template
            </Button>
          ) : (
            "Not Available"
          );
        },
      },
    },
  ];

  return (
    <React.Fragment>
      <DataTable columns={columns} options={options} data={broadcasts} />

      <BroadcastReport
        broadcastReport={broadcastReport}
        popup={popup}
        setPopup={setPopup}
        setLoadReport={setLoadReport}
      />
    </React.Fragment>
  );
}
