/* Routes for sidebar menu
  For a single entry: {
    name: Entry Display Name,
    icon: Entry Icon,
    ID: dashboardRoutes.[route name].ID,
    path: dashboardRoutes.[route name].path,
  }

  For a folder: {
    name: Folder Display Name,
    icon: Folder Icon,
    ID: random ID for key for component in the loop,
    children: [{
      name: Sub entry name,
      path: dashboardRoutes.[route name].path,
    }],
  }
*/

import dashboardRoutes from "../../../router/router components/dashboardLayoutRoutes";
import { PaletteRounded, QuestionAnswer } from "@mui/icons-material";
import { HiTemplate, HiHome, HiUsers } from "react-icons/hi";
import { AiFillRobot } from "react-icons/ai";
import { IoMdSettings } from "react-icons/io";
import { BsBroadcast } from "react-icons/bs";
import { RiUserSettingsFill } from "react-icons/ri";
import { FaQuestionCircle } from "react-icons/fa";
import { RiFeedbackFill, RiRobotFill } from "react-icons/ri";
import { FaWhatsapp } from "react-icons/fa";

const routes = [
  {
    name: "Home",
    icon: HiHome,
    ID: dashboardRoutes.homeRoute.ID,
    path: dashboardRoutes.homeRoute.path,
    disableAuth: dashboardRoutes.homeRoute.disableAuth,
  },
  // {
  //   name: "Insights",
  //   icon: BsGraphUp,
  //   ID: dashboardRoutes.insightsRoute.ID,
  //   path: dashboardRoutes.insightsRoute.path,
  // },
  {
    name: "Members",
    icon: RiUserSettingsFill,
    ID: dashboardRoutes.memberListRoute.ID,
    path: dashboardRoutes.memberListRoute.path,
  },

  //FAQs

  {
    name: "Faq",
    icon: FaQuestionCircle,
    ID: dashboardRoutes.faqsListRoute.ID,
    path: dashboardRoutes.faqsListRoute.path,
  },

  {
    name: "Chats",
    icon: QuestionAnswer,
    ID: dashboardRoutes.allChatsRoute.ID,
    path: dashboardRoutes.allChatsRoute.path + "all",
  },

  {
    name: "Manage Leads",
    icon: HiUsers,
    ID: dashboardRoutes.leadListRoute.path,
    children: [
      {
        name: "Leads",
        ID: dashboardRoutes.leadListRoute.ID,
        path: dashboardRoutes.leadListRoute.path,
      },
      {
        name: "Groups",
        ID: dashboardRoutes.groupListRoute.ID,
        path: dashboardRoutes.groupListRoute.path,
      },
    ],
  },

  {
    name: "Templates",
    icon: HiTemplate,
    ID: dashboardRoutes.templateListRoute.ID,
    path: dashboardRoutes.templateListRoute.path,
  },

  {
    name: "Broadcast",
    icon: BsBroadcast,
    ID: dashboardRoutes.broadcastListRoute.ID,
    path: dashboardRoutes.broadcastListRoute.path,
  },

  {
    name: "Manage",
    icon: IoMdSettings,
    ID: dashboardRoutes.customFieldList.path,
    children: [
      {
        ID: dashboardRoutes.labelListRoute.ID,
        name: "Labels",
        path: dashboardRoutes.labelListRoute.path,
      },

      {
        ID: dashboardRoutes.customFieldList.ID,
        name: "Custom Fields",
        path: dashboardRoutes.customFieldList.path,
      },
      // {
      //   ID: dashboardRoutes.integrationListRoute.ID,
      //   name: "Integrations",
      //   path: dashboardRoutes.integrationListRoute.path,
      // },
      {
        ID: dashboardRoutes.webhookListRoute.ID,
        name: "Webhooks",
        path: dashboardRoutes.webhookListRoute.path,
      },
      {
        ID: dashboardRoutes.permissionsGroupListRoute.ID,
        name: "Member Groups",
        path: dashboardRoutes.permissionsGroupListRoute.path,
      },
      {
        ID: dashboardRoutes.chatGptRoute.ID,
        name: "Chat GPT",
        path: dashboardRoutes.chatGptRoute.path,
      },
      {
        name: "Canned Response",
        icon: PaletteRounded,
        ID: dashboardRoutes.cannedMessagesListRoute.ID,
        path: dashboardRoutes.cannedMessagesListRoute.path,
      },
      {
        name: "Live Chat Report",
        icon: PaletteRounded,
        ID: dashboardRoutes.liveChatReportRoute.ID,
        path: dashboardRoutes.liveChatReportRoute.path,
      },
      {
        name: "Live Chat Config",
        icon: PaletteRounded,
        ID: dashboardRoutes.liveChatConfigRoute.ID,
        path: dashboardRoutes.liveChatConfigRoute.path,
      },
      {
        name: "Live Chat Insight",
        icon: PaletteRounded,
        ID: dashboardRoutes.liveChatInsightRoute.ID,
        path: dashboardRoutes.liveChatInsightRoute.path,
      },
      {
        name: "Chat Transcript",
        icon: PaletteRounded,
        ID: dashboardRoutes.chatsTranscriptsRoute.ID,
        path: dashboardRoutes.chatsTranscriptsRoute.path,
      },
      {
        name: "Livechat Request",
        icon: PaletteRounded,
        ID: dashboardRoutes.liveChatRequestRoute.ID,
        path: dashboardRoutes.liveChatRequestRoute.path,
      },
    ],
  },
  //bot train module
  {
    name: "Train Bot",
    icon: AiFillRobot,
    ID: dashboardRoutes.botEntityListRoute.ID,
    children: [
      {
        name: "Entity",
        ID: dashboardRoutes.botEntityListRoute.ID,
        path: dashboardRoutes.botEntityListRoute.path,
      },
      {
        name: "Intent",
        ID: dashboardRoutes.botIntentListRoute.ID,
        path: dashboardRoutes.botIntentListRoute.path,
      },
      {
        name: "Examples",
        ID: dashboardRoutes.botExampleRoute.ID,
        path: dashboardRoutes.botExampleRoute.path,
      },
      {
        name: "Slots",
        ID: dashboardRoutes.slotListRoute.ID,
        path: dashboardRoutes.slotListRoute.path,
      },
      {
        ID: dashboardRoutes.utteranceListRoute.ID,
        name: "Utterances",
        path: dashboardRoutes.utteranceListRoute.path,
      },
    ],
  },

  {
    name: "Feedback ",
    icon: RiFeedbackFill,
    ID: dashboardRoutes.feedbackDetailsRoute.ID,
    path: dashboardRoutes.feedbackDetailsRoute.path,
  },

  //style your bot
  {
    name: "Widget Style",
    icon: PaletteRounded,
    ID: dashboardRoutes.styleBotRoute.ID,
    path: dashboardRoutes.styleBotRoute.path,
  },

  //WhatsApp Onboarding Page
  {
    name: "WhatsApp Onboarding",
    icon: FaWhatsapp,
    ID: dashboardRoutes.whatsappOnboardingRoute.ID,
    path: dashboardRoutes.whatsappOnboardingRoute.path,
  },

  {
    name: "Email Broadcast",
    icon: BsBroadcast,
    ID: dashboardRoutes.broadcastPmcRoute.path,
    children: [
      {
        name: "Broadcast",
        ID: dashboardRoutes.emailBroadcastListRoute.ID,
        path: dashboardRoutes.emailBroadcastListRoute.path,
      },

      {
        name: "Templates",
        icon: HiTemplate,
        ID: dashboardRoutes.emailTemplateListRoute.ID,
        path: dashboardRoutes.emailTemplateListRoute.path,
      },
    ],
  },

  // pmc broadcast
  // {
  //   name: "Broadcast",
  //   icon: BsBroadcast,
  //   ID: dashboardRoutes.broadcastPmcRoute.path,
  //   children: [
  //     {
  //       name: "Email",
  //       ID: dashboardRoutes.emailBroadcastListRoute.ID,
  //       path: dashboardRoutes.emailBroadcastListRoute.path,
  //     },

  //     {
  //       name: "SMS",
  //       ID: dashboardRoutes.broadcastPmcRoute.ID,
  //       path: dashboardRoutes.broadcastPmcRoute.path + "?channel=message",
  //     },

  //     {
  //       name: "Whatsapp",
  //       ID: dashboardRoutes.broadcastPmcRoute.ID,
  //       path: dashboardRoutes.broadcastPmcRoute.path + "?channel=whatsapp",
  //     },
  //   ],
  // },

  // //sail Routes
  // {
  //   name: "Broadcast",
  //   icon: BsBroadcast,
  //   ID: dashboardRoutes.broadcastSailEmailListRoute.path,
  //   children: [
  //     {
  //       name: "Whatsapp",
  //       ID: dashboardRoutes.broadcastListRoute.ID,
  //       path: dashboardRoutes.broadcastListRoute.path,
  //     },
  //     {
  //       name: "Email",
  //       ID: dashboardRoutes.broadcastSailEmailListRoute.ID,
  //       path: dashboardRoutes.broadcastSailEmailListRoute.path,
  //     },

  //     {
  //       name: "SMS",
  //       ID: dashboardRoutes.broadcastSailSMSListRoute.ID,
  //       path: dashboardRoutes.broadcastSailSMSListRoute.path,
  //     },
  //   ],
  // },
];

export default routes;
