import { Formik } from "formik";
import { Grid } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

import FormDisplay from "./FormDisplay";
import SubmitButton from "./SubmitButtons";
import CreateButton from "./CreateButtons";
import validationSchema from "./validationSchema";
import EmailPreview from "./EmailTemplatePreview";
import pages from "./../../../../../constants/pages";
import { useAxios, useComponent } from "../../../../../hooks";
import { objectFromFormData } from "./emailTemplateDetailsObject";
import TemplateBody from "./email template body components/TemplateBody";
import TemplateSubject from "./email template subject components/TemplateSubject";
import TemplateAttachment from "./email template attachment components/TemplateAttachment";

export default function Form({
  formData,
  templateData,
  agentID,
  language,
  category,
  botDetails,
  pagePermissions,
}) {
  const axios = useAxios();
  const { alert } = useComponent();
  const { activeBot } = useSelector((state) => state.memberDetails);
  const navigation = useNavigate();

  const [addSample, setAddSample] = useState(false);
  const [editState, setEditState] = useState(false);

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={(formData, { setSubmitting }) => {
        var data = objectFromFormData(formData);

        axios({
          url:
            "/broadcast_email/template/" +
            (formData.ID ? formData.ID : "") +
            "?agent_id=" +
            activeBot.ID,
          method: !formData.ID ? "POST" : "PATCH",
          disableRedirect: true,
          data: data,
        }).then((response) => {
          if (response.status) {
            alert.success(response.message.displayMessage);
            console.log(response, "response");
            navigation(pages.emailTemplateList.route);
          }
          setSubmitting(false);
        });
      }}
    >
      {(formikProps) => (
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <FormDisplay
              formikProps={formikProps}
              templateData={templateData}
              agentID={agentID}
              language={language}
              category={category}
              addSample={addSample}
              botDetails={botDetails}
              pagePermissions={pagePermissions}
              editState={editState}
            />

            <TemplateSubject
              formikProps={formikProps}
              addSample={addSample}
              editState={editState}
            />

            <TemplateBody
              formikProps={formikProps}
              addSample={addSample}
              editState={editState}
            />

            <TemplateAttachment
              formikProps={formikProps}
              editState={editState}
            />

            {!formikProps.values.ID && (
              <SubmitButton
                formikProps={formikProps}
                addSample={addSample}
                setAddSample={setAddSample}
              />
            )}

            {!!formikProps.values.ID && (
              <CreateButton
                formikProps={formikProps}
                editState={editState}
                setEditState={setEditState}
              />
            )}
          </Grid>

          <Grid item container xs={3}>
            <Grid item position={"fixed"} xs={12}>
              <EmailPreview formikProps={formikProps} />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Formik>
  );
}
