import React from "react";

import { Grid, Button } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { Paper } from "../../../../../components";

export default function SubmitButton({ formikProps, setAddSample }) {
  const navigate = useNavigate();

  return (
    <Paper>
      <Grid item>
        <Button
          onClick={() => {
            navigate(-1);
            formikProps.handleReset();
          }}
          color="primary"
          variant="outlined"
        >
          Back
        </Button>
      </Grid>

      <Grid item xs />
      <Grid item>
        <Button
          disabled={
            formikProps.dirty
              ? formikProps.isSubmitting || !formikProps.isValid
              : !formikProps.dirty
          }
          onClick={formikProps.handleSubmit}
          color="primary"
          variant="contained"
        >
          Create Template
        </Button>
      </Grid>
    </Paper>
  );
}
