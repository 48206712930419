import React, { useState } from "react";
import * as Yup from "yup";
import {
  Button,
  Dialog as MUIDialog,
  Grid,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
  Divider,
} from "@mui/material";
import { Formik } from "formik";
import { Cancel } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";

import FilterDataForm from "./FilterDataForm";
import { useAxios, useComponents } from "../../../hooks";
import { Autocomplete } from "../../../components";

const useStyles = makeStyles(() => ({
  paper: { minWidth: "1000px" },
}));

export default function FilterDataDialog({
  setFilter,
  filter,
  setUsers,
  activeBot,
  filterColumns,
  memberList,
  userData,
  labels,
  applyFilter,
  setApplyFilter,
}) {
  const classes = useStyles();
  const axios = useAxios();

  const bots = useSelector((state) => state.memberDetails.bots);

  const handleClose = () => {
    setFilter(false);
  };

  const [filterData, setFilterData] = useState({
    filters: [
      {
        key: "",
        value: "",
        operator: "",
        relation: "",
      },
    ],
    filtersLabels: [],
  });

  function handleClearFilter() {
    setFilterData({
      filters: [
        {
          key: "",
          value: "",
          operator: "",
          relation: "",
        },
      ],
      filtersLabels: [],
    });

    axios({
      url: "/leads/get_leads?agent_id=" + activeBot.ID + "&skip=0&limit=50",
      method: "POST",
      data: {},
    }).then((response) => {
      if (response.status) {
        setUsers({
          users: response.data.paginatedResults,
          totalCount: response.data.totalCount[0]?.count || 0,
        });

        handleClose();
      }
    });
  }
  return (
    <React.Fragment>
      <MUIDialog
        classes={{ paper: classes.paper }}
        open={filter}
        onClose={handleClose}
      >
        <Grid
          container
          spacing={0}
          style={{
            borderBottom: "1px solid #cbcbcb",
          }}
        >
          <Grid style={{ padding: "16px 24px" }} item xs={8}>
            <Typography variant="h5">Filter Data</Typography>
          </Grid>

          <Grid item xs={4}>
            <IconButton
              disableRipple
              style={{ float: "right", padding: "16px 24px" }}
              onClick={handleClose}
            >
              <Cancel />
            </IconButton>
          </Grid>
        </Grid>

        <Formik
          initialValues={filterData}
          enableReinitialize
          validateOnMount
          validationSchema={validationSchema}
          onSubmit={(formData, { setSubmitting }) => {
            let url =
              "/leads/get_leads?agent_id=" + activeBot.ID + "&skip=0&limit=50";

            let filterData = {};
            if (
              formData.filters[0].operator !== undefined &&
              formData.filters[0].operator !== ""
            ) {
              filterData.filters = [];
              for (let data of formData.filters) {
                filterData.filters.push({
                  key: data.key,
                  value: data.value,
                  operator: data.operator,
                  relation: data.relation,
                });
              }
              url = url + "&filter=1";
              setApplyFilter((pre) => ({
                isFilter: true,
                url: "&filter=1",
                data: pre.filterData,
              }));
            }
            filterData.labels = [];

            for (let data of formData.filtersLabels) {
              filterData.labels.push(data._id);
            }
            setFilterData(formData);
            axios({
              url: url,
              method: "POST",
              data: filterData,
            }).then((response) => {
              if (response.status) {
                setSubmitting(false);

                setUsers({
                  users: response.data.paginatedResults,
                  totalCount: response.data.totalCount[0]?.count || 0,
                });

                setApplyFilter((pre) => ({
                  isFilter: pre.isFilter,
                  url: pre.url,
                  data: filterData,
                }));

                handleClose();
              } else {
                setSubmitting(false);
              }
            });
          }}
        >
          {(dialogFormikProps) => (
            <React.Fragment>
              <DialogContent
                style={{
                  paddingTop: "10px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      variant="caption1"
                      style={{ color: "rgb(128, 128, 128)" }}
                    >
                      Apply filter to the table data
                    </Typography>
                  </Grid>

                  <FilterDataForm
                    bots={bots}
                    memberList={memberList}
                    dialogFormikProps={dialogFormikProps}
                    filterColumns={filterColumns}
                  />
                </Grid>
              </DialogContent>
              <Divider />
              <Grid container spacing={4} sx={{ pl: "25px", pt: "25px" }}>
                <Grid item container xs={12}>
                  <Typography
                    variant="caption1"
                    style={{ color: "rgb(128, 128, 128)" }}
                  >
                    Apply filter to the Label
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Autocomplete
                    multiple={true}
                    name="filtersLabels"
                    label="Select Fields"
                    defaults={{
                      primaryKey: "title",
                      displayLabel: "title",
                    }}
                    required={false}
                    options={labels}
                    formikProps={dialogFormikProps}
                    sx={{ mt: "10px" }}
                  />
                </Grid>
              </Grid>

              <DialogActions style={{ padding: "12px 25px 24px" }}>
                <Grid container spacing={3}>
                  <Grid item xs={9}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                  </Grid>

                  <Grid
                    item
                    xs={3}
                    display={"flex"}
                    justifyContent={"space-between"}
                    style={{ textAlign: "end" }}
                  >
                    <Button
                      onClick={handleClearFilter}
                      variant="contained"
                      color="primary"
                      disabled={dialogFormikProps.dirty}
                    >
                      Clear Filter
                    </Button>

                    <Button
                      onClick={dialogFormikProps.handleSubmit}
                      variant="contained"
                      color="primary"
                      disabled={!dialogFormikProps.dirty}
                    >
                      Apply Filter
                    </Button>
                  </Grid>
                </Grid>
              </DialogActions>
            </React.Fragment>
          )}
        </Formik>
      </MUIDialog>
    </React.Fragment>
  );
}
const validationSchema = Yup.object().shape({
  filters: Yup.array(
    Yup.object().shape({
      key: Yup.string(),
      operator: Yup.string(),
      value: Yup.string(),
    })
  ),
});
