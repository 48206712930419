import React, { useState, useRef, useEffect } from "react";
import {
  Grid,
  Button,
  ButtonGroup,
  InputAdornment,
  Typography,
} from "@mui/material";
import { Add } from "@mui/icons-material";

import { FaItalic, FaBold, FaStrikethrough, FaSmile } from "react-icons/fa";
import { GrMonospace } from "react-icons/gr";

import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";
import {
  ComponentHeader,
  Paper,
  TextField,
} from "./../../../../../../components";

export default function TemplateBody({ formikProps, addSample, editState }) {
  const [showEmoji, setShowEmoji] = useState(false);

  const onEmojiClick = (emoji, emojiObject) => {
    if (formikProps.values?.templateBody?.text?.length >= 1024) {
      return;
    }

    formikProps.setValues((prevVal) => ({
      ...prevVal,
      templateBody: setText(prevVal.templateBody, prevVal),
    }));
    function setText(templateBody, prevVal) {
      templateBody.text = prevVal?.templateBody?.text + emoji.native;
      return templateBody;
    }
  };

  const handleAddVariable = () => {
    var textHeader = document.getElementsByName("templateBody.text")[0].value;
    var count_var_1 = (textHeader.match(/\{/g) || []).length;

    if (textHeader.length >= 1024) {
      return;
    }

    formikProps.setValues((prevVal) => ({
      ...prevVal,
      templateBody: setVariables(prevVal.templateBody),
    }));
    function setVariables(templateBody) {
      templateBody.text = textHeader + "{" + (count_var_1 + 1) + "}";
      templateBody.count_of_variable = count_var_1 + 1;
      return templateBody;
    }
  };

  const handleBodyChange = (event) => {
    var textHeader = document.getElementsByName("templateBody.text")[0].value;

    var count_var_1 = (textHeader.match(/\{/g) || []).length;

    formikProps.setValues((prevVal) => ({
      ...prevVal,
      templateBody: setVariables(prevVal.templateBody),
    }));
    function setVariables(templateBody) {
      templateBody.text = event.target.value;
      templateBody.count_of_variable = count_var_1 === 0 ? 0 : count_var_1;
      templateBody.example = [];
      return templateBody;
    }
  };

  let useClickForOutside = (handler) => {
    let emoji = useRef();

    useEffect(() => {
      let maybeHandler = (event) => {
        if (!emoji.current.contains(event.target)) {
          handler();
        }
      };
      document.addEventListener("mousedown", maybeHandler);
      return () => {
        document.removeEventListener("mousedown", maybeHandler);
      };
    });

    return emoji;
  };

  let emoji = useClickForOutside(() => {
    setShowEmoji(false);
  });

  const makeItAsSelected = (key) => {
    var symbol1;
    var symbol2;
    if (key === "bold") {
      symbol1 = "<b>";
      symbol2 = "</b>";
    } else if (key === "italic") {
      symbol1 = "<i>";
      symbol2 = "</i>";
    } else if (key === "strikethrough") {
      symbol1 = "<s>";
      symbol2 = "</s>";
    } else if (key === "monospace") {
      symbol1 = "```";
      symbol2 = "```";
    }

    var selectedField = document.getElementById("bodyText");
    var start = selectedField.selectionStart;
    var end = selectedField.selectionEnd;
    var t = selectedField.value.substr(
      selectedField.selectionStart,
      selectedField.selectionEnd - selectedField.selectionStart
    );

    if (start >= 0 && end !== 0 && Boolean(t)) {
      var text = selectedField.value;

      text = text.split("");

      text.splice(start, 0, symbol1);
      text.splice(end + 1, 0, symbol2);

      text = text.join("");

      formikProps.setValues((prevVal) => ({
        ...prevVal,
        templateBody: setText(prevVal.templateBody),
      }));
      function setText(templateBody) {
        templateBody.text = text;
        return templateBody;
      }

      selectedField.focus();
    }
  };

  return (
    <Paper>
      <Grid style={{ alignSelf: "center" }} item xs={12}>
        <ComponentHeader
          title={"Template Body"}
          subtitle={
            "Enter the text for your message in the language you have selected. You can use as many variables. "
          }
        />
        <Typography
          variant="subtitle2"
          gutterBottom
          style={{ color: "#808080b3" }}
        >
          While using Bold, Italic, Strikethrough make sure there should not be
          any space between the word and symbol.
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <TextField
          label="Template Body"
          id="bodyText"
          name="templateBody.text"
          fullWidth
          required
          multiline
          case="none"
          onChange={handleBodyChange}
          rows={5}
          formikProps={formikProps}
          disabled={Boolean(formikProps.values.ID) && !editState}
          inputProps={{
            maxLength: 1024,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <div
                  style={{ position: "absolute", bottom: "2px", right: "5px" }}
                >
                  {formikProps.values?.templateBody?.text?.length}/1024
                </div>
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item />
      <Grid textAlign={"end"} item xs>
        <Button
          disabled={Boolean(formikProps.values.ID) && !editState}
          variant="outlined"
          color="primary"
          onClick={handleAddVariable}
        >
          <Add /> &nbsp;Add Variable
        </Button>
      </Grid>

      <Grid textAlign={"end"} item>
        <ButtonGroup size="medium">
          <Button
            style={{ padding: "10px 20px" }}
            onClick={() => makeItAsSelected("italic")}
            disabled={Boolean(formikProps.values.ID) && !editState}
            key="Italic"
          >
            <FaItalic />
          </Button>

          <Button
            style={{ padding: "10px 20px" }}
            onClick={() => makeItAsSelected("bold")}
            disabled={Boolean(formikProps.values.ID) && !editState}
            key="Bold"
          >
            <FaBold />
          </Button>

          <Button
            style={{ padding: "10px 20px" }}
            onClick={() => makeItAsSelected("strikethrough")}
            disabled={Boolean(formikProps.values.ID) && !editState}
            key="Strikethrough"
          >
            <FaStrikethrough />
          </Button>

          {/* <Button
            style={{ padding: "10px 20px" }}
            onClick={() => makeItAsSelected("monospace")}
            disabled={Boolean(formikProps.values.ID) && !editState}
            key="Monospace"
          >
            <GrMonospace />
          </Button> */}

          <div ref={emoji} style={{ position: "relative" }}>
            <Button
              onClick={() => setShowEmoji(!showEmoji)}
              disabled={Boolean(formikProps.values.ID) && !editState}
              style={{ padding: "10px 20px", marginLeft: "2px" }}
              key="Smile"
            >
              <FaSmile />
            </Button>

            {showEmoji && (
              <Picker
                style={{
                  zIndex: "9999",
                  position: "absolute",
                  height: "324px",
                  width: "275px",
                  margin: "37px 0 0 0",
                  right: "0px",
                  cursor: "pointer",
                }}
                showPreview={false}
                onClick={onEmojiClick}
              />
            )}
          </div>
        </ButtonGroup>
      </Grid>
    </Paper>
  );
}
