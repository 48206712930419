import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import pages from "../../../../constants/pages";
import { useAxios, useComponent } from "../../../../hooks";
import Table from "./email broadcast list component/Table";
import { Loader, PageHeader } from "../../../../components";
import { apiComplete, startLoader } from "../../../../redux/slices/loaderSlice";
import EmailPreview from "./../email template components/template details component/EmailTemplatePreview";

export default function EmailBroadcastList({ pageID }) {
  const axios = useAxios();
  const dispatch = useDispatch();
  const { pagePermissions } = useComponent({ pageID });
  const { activeBot } = useSelector((state) => state.memberDetails);

  const [template, setTemplate] = useState([]);
  const [broadcasts, setBroadcasts] = useState([]);
  const [broadcastID, setBroadcastID] = useState([]);

  useEffect(() => {
    dispatch(startLoader());

    axios({
      url: "/broadcast_email/get_broadcast_list?agent_id=" + activeBot.ID,
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        setBroadcasts(response.data);
      }
      dispatch(apiComplete());
    });
  }, [axios, dispatch, activeBot.ID]);

  useEffect(() => {
    axios({
      url: "/broadcast_email/template?agent_id=" + activeBot.ID,
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        setTemplate(response.data);
      }
    });
  }, [axios, dispatch, activeBot.ID]);

  return (
    <Loader>
      <PageHeader
        pageName="Broadcast"
        addIcon={[
          "Create Broadcast",
          pages.pmcRoute.route + "?channel=email",
          pagePermissions.create,
        ]}
      />

      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Table broadcasts={broadcasts} setBroadcastID={setBroadcastID} />
        </Grid>

        <Grid item xs={4}>
          <Grid item container position={"fixed"} sx={{ width: "30%" }}>
            <EmailPreview
              templates={template.filter((item) => {
                return item._id === broadcastID;
              })}
            />
          </Grid>
        </Grid>
      </Grid>
    </Loader>
  );
}
